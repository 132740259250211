/**
 * 路由配置
 */
import Vue from 'vue';

import VueRouter from 'vue-router';
import { routes } from './routes';

Vue.use(VueRouter);

const router = new VueRouter({
  routes,
  mode: 'history',
  scrollBehavior() {
    return { y: 0 };
  }
});

/**
 * 路由守卫
 */
router.beforeEach((to, from, next) => {
  next();
});

export default router;
