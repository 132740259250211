/**
 * 登录状态管理
 */
// import { getCurrentUser } from '@/api/login';

export default {
  namespaced: true,
  state: {
    // 当前登录用户信息
    info: null
  },
  mutations: {
    // 设置登录用户的信息
    setUserInfo(state, info) {
      state.info = info;
    }
  },
  actions: {
    /**
     * 更新用户信息
     */
    setInfo({ commit }, value) {
      commit('setUserInfo', value);
    }
  }
};
