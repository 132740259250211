/**
 * 静态路由
 */
export const routes = [
  {
    path: '/',
    component: () => import('@/views/index/index.vue')
  },
  {
    path: '/login',
    component: () => import('@/views/login/index.vue')
  },
  // 404
  {
    path: '*',
    component: () => import('@/views/exception/404/index.vue')
  }
];
