import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import dataV from '@jiaminghi/data-view';
import VueParticles from 'vue-particles';

import './styles/index.scss';
import '@/assets/iconfont/iconfont.css';

Vue.config.productionTip = false;

Vue.use(dataV);
Vue.use(VueParticles);

new Vue({
  router,
  store,

  render: (h) => h(App)
}).$mount('#app');
